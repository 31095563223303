import React from "react";
import "./Footer.css";

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE INTERPROIECT SRL.pdf";

const Footer = () => {
  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  const contactInfo = [
    {
      icon: "fas fa-map-marker-alt",
      title: "Adresa",
      content:
        "Strada Vadul Bistritei, Nr. 29, Bloc 29, Sc. A, Et. 8, Ap. 22, Bacău, Județ Bacău",
      delay: 100,
    },
    {
      icon: "fas fa-envelope",
      title: "Email",
      content: "interproiect@gmail.com",
      delay: 200,
    },
    {
      icon: "fas fa-phone",
      title: "Telefon",
      content: "+40723644115",
      delay: 300,
    },
  ];

  const socialLinks = [
    {
      icon: "fab fa-facebook-f",
      url: "https://www.facebook.com/profile.php?id=61569727493713",
    },
    {
      icon: "fab fa-linkedin-in",
      url: "https://www.linkedin.com/in/inter-proiect-9b8b6533a/",
    },
  ];

  return (
    <footer className="footer-section">
      <div className="main-content">
        <div className="footer-content">
          <div className="contact-info">
            <h4>Contact</h4>

            <div className="contact-grid">
              {contactInfo.map((item, index) => (
                <div
                  className="contact-item"
                  data-aos="fade-up"
                  data-aos-delay={item.delay}
                  key={index}
                >
                  <i className={item.icon}></i>
                  <div className="contact-item-content">
                    <h5>{item.title}</h5>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="map-container" data-aos="fade-up">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.885207767262!2d26.90873661582118!3d46.5672199791314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b4cf3d4c75875d%3A0x27f5ddb8813e35b4!2sStrada%20Vadul%20Bistri%C8%9Bei%2029%2C%20Bac%C4%83u!5e0!3m2!1sro!2sro!4v1640183982985!5m2!1sro!2sro"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Company Location"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="bottom-content">
          <div className="social-links">
            {socialLinks.map((link, index) => (
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <i className={link.icon}></i>
              </a>
            ))}
          </div>

          <div className="copyright">
            Copyright ©{new Date().getFullYear()},{" "}
            <a href="/" className="text-decoration-none">
              INTERPROIECT
            </a>
          </div>
        </div>

        <div className="eu-funding-info">
          <p className="info-text">
            Pentru informații detaliate despre celelalte programe cofinanțate de
            Uniunea Europeană, vă invităm să vizitați{" "}
            <a href="https://www.mfe.gov.ro" className="info-link">
              www.mfe.gov.ro
            </a>
          </p>
          <div className="funding-links">
            <div className="url-links">
              <a href="http://www.regionordest.ro" className="web-link">
                www.regionordest.ro
              </a>
              <a
                href="https://www.facebook.com/Regio.NordEst.ro"
                className="web-link"
              >
                www.facebook.com/Regio.NordEst.ro
              </a>
            </div>
            <div className="aproape-button">Aproape de tine</div>
          </div>
        </div>

        <div className="footer-banner">
          <div className="logo-row">
            <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
            <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
            <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
            <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
          </div>
          <button onClick={handleInfoClick} className="info-button">
            Proiect digitalizare - Află mai multe
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
