import React, { useEffect } from 'react';
import AOS from 'aos';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Footer from './components/Footer';
import FloatingIcons from './components/FloatingIcons';
import ChatSystem from './components/ChatSystem';
import SplineBackground from './components/SplineBackground';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import './App.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100
    });
  }, []);

  return (
    <div className="App">
      <SplineBackground />
      <Navbar />
      <Hero />
      <Services />
      <Footer />
      <FloatingIcons />
      <ChatSystem />
    </div>
  );
}

export default App;