import React, { useEffect } from "react";
import "./Hero.css";

const Hero = () => {
  useEffect(() => {
    document.querySelector(".hero-title").classList.add("show");
  }, []);

  return (
    <section className="hero-section">
      <div className="hero-lines">
        {/* <div className="hero-line"></div>
        <div className="hero-line"></div>
        <div className="hero-line"></div>
        <div className="hero-line"></div>
        <div className="hero-line"></div> */}
      </div>
      <div className="hero-background"></div>
      <div className="hero-content">
        <h1 className="hero-title">
          <span>E</span>
          <span>x</span>
          <span>c</span>
          <span>e</span>
          <span>l</span>
          <span>e</span>
          <span>n</span>
          <span>t</span>
          <span>a</span>&nbsp;
          <span>i</span>
          <span>n</span>&nbsp;
          <br />
          <span>p</span>
          <span>r</span>
          <span>o</span>
          <span>i</span>
          <span>e</span>
          <span>c</span>
          <span>t</span>
          <span>a</span>
          <span>r</span>
          <span>e</span>
        </h1>
        <div className="project-info">
          <p>
            <strong>30 de ani de</strong> proiectare tehnica in constructii <br />
            Combinand inovatia cu angajamentul calitatii sustenabile
          </p>
          
        </div>
      </div>
    </section>
  );
};

export default Hero;
