import React from 'react';
import './SplineBackground.css';

const SplineBackground = () => {
  return (
    <div className="spline-container">
      <iframe
        src="https://my.spline.design/flow-e031db9fe8f4e1eb46ca08691d032729/"
        frameBorder="0"
        width="100%"
        height="100%"
        title="spline-background"
      />
    </div>
  );
};

export default SplineBackground;