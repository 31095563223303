import React, { useEffect } from 'react';
import AOS from 'aos';
import './Services.css';

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    });
  }, []);

  const services = [
    {
      icon: 'fas fa-drafting-compass',
      title: 'Proiectare tehnică detaliată',
      description: 'Soluții complete de proiectare tehnică pentru orice tip de construcție'
    },
    {
      icon: 'fas fa-building',
      title: 'Consultanță în arhitectură',
      description: 'Expertiză în design arhitectural și consultanță specializată'
    },
    {
      icon: 'fas fa-cogs',
      title: 'Soluții inovatoare de inginerie',
      description: 'Abordări inovatoare pentru provocări complexe de inginerie'
    },
    {
      icon: 'fas fa-hands-helping',
      title: 'Suport complet pentru proiecte',
      description: 'Asistență completă pe toată durata proiectului'
    }
  ];

  const stats = [
    { number: '30+', label: 'Ani de Experiență' },
    { number: '500+', label: 'Proiecte Finalizate' },
    { number: '100%', label: 'Clienți Mulțumiți' }
  ];

  return (
    <section className="services-section">
      <div className="container">
        <h2 className="section-title" data-aos="fade-up">Serviciile Noastre</h2>

        <div className="services-grid" data-aos="fade-up">
          {services.map((service, index) => (
            <div 
              className="service-item" 
              data-aos="fade-up" 
              data-aos-delay={100 * (index + 1)}
              key={index}
            >
              <i className={`${service.icon} service-icon`}></i>
              <h4>{service.title}</h4>
              <p>{service.description}</p>
            </div>
          ))}
        </div>

        <div className="stats-container" data-aos="fade-up">
          <div className="stats-row">
            <div className="stats-grid">
              {stats.map((stat, index) => (
                <div className="stats-counter" key={index}>
                  <div className="counter-number">{stat.number}</div>
                  <div className="counter-label">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="custom-shape-divider">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default Services;