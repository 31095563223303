import React, { useState } from 'react';
import './FloatingIcons.css';

const FloatingIcons = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    const chatContainer = document.getElementById('chat-container');
    if (chatContainer) {
      chatContainer.style.display = isChatOpen ? 'none' : 'flex';
    }
  };

  return (
    <div className="floating-icons">
      <a
        href="https://wa.me/40723644115"
        className="floating-icon"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <i className="fab fa-whatsapp"></i>
      </a>
      <a
        href="tel:+40723644115"
        className="floating-icon"
        data-aos="fade-left"
        data-aos-delay="200"
      >
        <i className="fas fa-phone"></i>
      </a>
    
    </div>
  );
};

export default FloatingIcons;