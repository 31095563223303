import React, { useEffect } from 'react';
import './Navbar.css';

const Navbar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleToggle = () => {
    const navbarCollapse = document.querySelector('.navbar-collapse');
    const navbarToggler = document.querySelector('.navbar-toggler');
    navbarCollapse.classList.toggle('show');
    navbarToggler.classList.toggle('active');
  };

  return (
    <nav className="navbar">
      <div className="container-nav">
        <a href="/" className="navbar-brand">
          <img src="/logo3.png" alt="InterProiect Logo" />
        </a>

        <button 
          className="navbar-toggler" 
          type="button" 
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        <div className="navbar-collapse">
          <div className="navbar-nav">
            <a href="https://dashboard.inter-proiect.com" className="login-btn">
              <span>LOGIN</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;